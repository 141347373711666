/*
 * @Author: Yran
 * @Date: 2021-06-09 11:06:19
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-13 13:56:14
 */
import axios from '@/utils/axios';

const apiUrl = {
    /**********************自定义列信息**************************************/
    getTrendByMediaAccountId: '/sys/mediaAccount/getTrend',
    getListSearchCaliber: '/sys/mediaAdvanceFilter/getListSearchCaliber',
    getColumns: '/columns'
};



//获取列表数据的方法
export function postTrendByMediaAccountId(params) {
    return axios.post(apiUrl.getTrendByMediaAccountId, params);
}
//获取指标集合只含图表数据的方法
export function postListSearchCaliber(params) {
    return axios.post(apiUrl.getListSearchCaliber, params);
}

// 获取首页详情指标数据
export function getColumns(channel) {
    return axios.get(apiUrl.getColumns + '?channel=' + channel);
}

