<!--
 * @Author: Yran
 * @Date: 2021-08-03 10:28:20
 * @LastEditors: Yran
 * @LastEditTime: 2021-08-16 16:46:16
-->
<template>
  <div>
    <div class="table-wrapper">
      <div class="table-toolbar flexJustifyBetween">
        <span class="weight">数据表格</span>
        <a-space class="toolbar-right" :size="12">
          <SelectMultiple class="select-tagmode" style="width: 144px" v-model:value="tableCondition.projectCodeList" :select-data="tableCondition.projectCodeList" :label-in-value="true" placeholder="细分维度" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="dimensionOptions" select-option-value="projectCode" select-option-label="projectName" :disabled="tableLoading" />
          <a-button :loading="excelExporting" :disabled="tableLoading" @click="onExportExcelBtnClick">
            导出Excel
            <template #icon>
              <CloudDownloadOutlined />
            </template>
          </a-button>
          <caliber v-show="filterCondition.mediaChannelList === 'GDT'" style="width: 200px" @selectItem="onTJKJSelect" :disabled="tableLoading"></caliber>
          <TempSelect :temp-list="tempList" @getTempName="getTempNameValue" @editTemp="editTemp" @deleteTemp="deleteTemp" @add-temp="addTemp" :disabled="tableLoading" />
        </a-space>
      </div>
      <datatable ref="imageTable" :table-data="materialTableData" :strip-show="true" :cols-setting="tableColumns" :row-selection="null" :row-key="record => record.id + record.mediaChannel" :table-loading="tableLoading || tableSetLoading" :pagination="tablePagination" :fixed-first-row="true" @table-change="onTableChange">
        <template #url="{ record }">
          <div v-if="record.url && record.url !== '-'" class="flexJustifyStart" style="padding-top: 8px; padding-bottom: 5px">
            <div class="flexAlignBetween">
              <img class="table-img" :src="getThumbOSSUrl(record)" :alt="record.fileName" style="cursor: pointer" title="点击预览" />
              <div class="img-hover position-abs flexJustifyCenter flexAlignCenter" @click="preview(record)">
                <PlayCircleOutlined class="img-hover-play" :style="{ fontSize: '24px', color: '#fff' }" />
              </div>
            </div>
            <div class="flexAlignBetween pl10">
              <a-tooltip :title="record.fileName" class="align-l textHide-2rows" style="width: 18em">
                <a-tag color="#2F70F4" style="border-radius: 4px">{{ columnMap(materialChannelOptions, record.materialChannel, 'value', 'label') }}</a-tag>
                {{ record.fileName }}
              </a-tooltip>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </datatable>
    </div>

    <!-- 自定义列弹窗 -->
    <CustomizeColumn :show-check="false" :selected-indicator="selectedIndicator" :fixed-list="fixedList" :column-name="editTempName" title-tips="（勾选媒体渠道的指标，请在对应的渠道下查看）" :menu-list="menuList" :modal-visible="modalVisible" @getDraggableList="getDraggableList" @get-temp-data="getTempData" />
  </div>
</template>
<script>
// 插件 start //
import _ from 'lodash';
import { CloudDownloadOutlined, PlayCircleOutlined } from '@ant-design/icons-vue';
// 插件 end //
// api start //
import { getMaterialPageData, exportExcel, getAllTemp, getChooseTemp, getTempName } from '@/api/material/material.js';
import { getDataList } from '@/api/materialAnalysis/index.js';
// api end //
// 组件 start //
import { getUrl } from '@/utils/oss.js';
import { addChineseUnit, isNumber, unique } from '@/utils/utils.js';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import caliber from '@/components/Customize/caliber.vue';
import TempSelect from '@/components/Customize/templateSelect.vue';
import datatable from '@/components/Customize/table.vue';
import CustomizeColumn from '@/components/Customize/customizeColumnModal.vue';
// 组件 end //
export default {
  emits: ['preview'],
  components: { CloudDownloadOutlined, PlayCircleOutlined, SelectMultiple, caliber, TempSelect, datatable, CustomizeColumn },
  props: {
    filterCondition: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterBtnClick: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    filterCondition: {
      handler(newVal) {},
      deep: true,
    },
    'filterCondition.mediaChannelList'(newVal) {
      this.mediaChannelChange();
    },
    filterBtnClick(newVal) {
      this.getMaterialPageListData();
    },
  },
  data() {
    return {
      tableCondition: {}, // 表格工具栏筛选项
      tableLoading: false, // 表格加载
      tableSetLoading: false, // 表格列加载
      dimensionOptions: [], // 维度列表
      excelExporting: false, // 导出excel加载
      tempList: [], // 模板列表
      materialTableData: [], // 表格数据
      tableColumns: [
        // 表格列
        {
          title: '素材ID',
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          width: 100,
        },
        {
          title: '文件预览',
          dataIndex: 'url',
          key: 'url',
          align: 'center',
          slots: {
            customRender: 'url',
          },
          width: 410,
          fixed: 'left',
        },
      ],
      tableSourceColumns: [
        // 表格列备份
        {
          title: '素材ID',
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          width: 100,
        },
        {
          title: '文件预览',
          dataIndex: 'url',
          key: 'url',
          align: 'center',
          slots: {
            customRender: 'url',
          },
          width: 410,
          fixed: 'left',
        },
      ],
      tablePagination: {
        // 表格分页
        limit: '31',
        page: '1',
        pageSize: 51,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['11', '21', '31', '51'],
      },
      materialChannelOptions: [
        // 素材类型
        { label: '内部制作', value: 'NBZZ' },
        { label: '外部采购', value: 'WBCG' },
        { label: '客户提供', value: 'KHTG' },
        { label: '情景剧', value: 'QJJ' },
        { label: '剪辑', value: 'YSJJ' },
        // { label: 'AE', value: 'AE' },
        { label: '采购', value: 'CG' },
      ],
      tableSorter: {
        // 表格排序
        order: 'desc',
        orderField: ['createTime'],
      },
      selectedIndicator: [], // 已选指标项
      fixedList: [], // 固定指标项
      editTempName: null, // 编辑列模板
      menuList: [], // 模板列表
      modalVisible: false, // 是否打开自定义指标弹窗
      activeTemp: null, // 当前选中模板名称
      materialType: this.$route.query.materialType || 'IMAGE', // 当前页面是图片数据还是视频数据
      mediaList: [
        // 媒体渠道
        { text: '巨量引擎', value: 'TT' },
        { text: '腾讯广告', value: 'GDT' },
        { text: '磁力引擎', value: 'KS' },
      ],
    };
  },
  created() {
    this.getAllTemp().then(() => {
      this.getTempName();
    });
  },
  mounted() {},
  methods: {
    /** 获取表格数据
     * @param {*}
     * @return {*}
     */
    async getMaterialPageListData() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      if (Array.isArray(this.tableSorter.orderField) && this.tableSorter.orderType) {
        this.tableSorter.orderField = [];
        this.tableSorter.orderField.push(this.tableSorter.orderType);
      } else if (this.tableSorter.orderType) {
        this.tableSorter.orderField = [];
        this.tableSorter.orderField.push(this.tableSorter.orderType);
      }
      if (this.tableSorter.orderField?.length > 1) {
        this.tableSorter.orderField = unique(this.tableSorter.orderField);
      }
      let postData = {
        ...this.tablePagination,
        ...this.filterCondition,
        limit: this.tablePagination.limit - 1,
        pageSize: this.tablePagination.pageSize - 1,
        materialType: this.materialType,
        timeline: this.filterCondition.mediaChannelList === 'GDT' ? this.activeConfigType : null,
        ...this.tableSorter,
      };
      if (this.filterCondition.mediaChannelList) {
        postData.mediaChannelList = Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList];
      } else {
        postData.mediaChannelList = [];
      }
      if (postData.materialIds) {
        if (this.filterCondition.materialIds.indexOf(' ') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(' ');
        } else if (this.filterCondition.materialIds.indexOf(',') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(',');
        } else if (this.filterCondition.materialIds.indexOf(';') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(';');
        } else {
          postData.materialIdList = [this.filterCondition.materialIds];
        }
      }

      if (postData.projectCodeList) {
        postData.projectCodeList = [];
        this.filterCondition.projectCodeList.forEach(p => {
          postData.projectCodeList.push(p.value);
        });
      }
      let res = await getMaterialPageData(postData);
      if (res.code === 0) {
        if (res.page?.list && !res.page?.list[0].id) {
          res.page.list[0].id = '总计';
        }
        res.page?.list.forEach(item => {
          if (item.url && item.url !== '-') {
            const itemCopy = _.cloneDeep(item);
            item.children = [{ ...itemCopy }];
          }
        });
        this.materialTableData = res.page?.list;
        this.tableSourceData = _.cloneDeep(this.materialTableData);
        this.tablePagination.current = res.page?.currPage;
        this.tablePagination.pageSize = res.page?.pageSize + 1;
        this.tablePagination.total = res.page?.totalCount;
        this.tableLoading = false;
        if (!this.amountShowDetail) {
          this.formatAmount(this.materialTableData);
        }
      }
    },

    /** 预览视频
     * @param {*} fileName 视频名称
     * @return {*}
     */
    async preview(record) {
      this.$emit('preview', record);
    },

    /** 获取所有模板数据
     * @param {*}
     * @return {*}
     */
    async getAllTemp() {
      let res = await getAllTemp();
      res.list?.forEach(item => {
        item.info?.forEach(childItem => {
          childItem.customConfigId = childItem.id;
          childItem.info?.forEach(info => {
            info.customConfigId = info.id;
          });
        });
      });
      this.menuList = res.list;
      this.mediaChannelChange();
    },

    /** 获取模板名称
     * @param {*}
     * @return {*}
     */
    getTempName() {
      getTempName().then(res => {
        if (res.code == 0) {
          this.activeTemp = '默认指标';
          this.tempList = res.list;
          this.tempList.unshift({ tempName: '默认指标' });
          if (this.activeTemp && this.activeTemp !== '默认指标') {
            this.getChooseTemp();
          } else {
            const list = this.menuList.filter(item => {
              return item.key == 'SHARE_DATA';
            })?.[0]?.info;
            this.setColumn(list);
          }
        }
      });
    },

    /** 获取选中的模板已选中指标
     * @param {*}
     * @return {*}
     */
    async getChooseTemp() {
      if (this.activeTemp && this.activeTemp !== '默认指标') {
        let res = await getChooseTemp(this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);
      }
    },

    /** 设置自定义列
     * @param {*}
     * @return {*}
     */
    setColumn(arr) {
      this.tableSetLoading = true;
      const _this = this;
      setTimeout(() => {
        this.tableColumns = _.cloneDeep(this.tableSourceColumns);
        arr?.forEach(item => {
          const itemChannel = item.oneColumnKeys?.split('_')[0];
          if ((_this.filterCondition.mediaChannelList && _this.filterCondition.mediaChannelList.indexOf(itemChannel) !== -1) || itemChannel === 'SHARE') {
            this.tableColumns?.push({
              title: item.columnValue.indexOf('率') !== -1 ? item.columnValue + '(%)' : item.columnValue,
              dataIndex: item.columnKey,
              width: 80,
              sorter: true,
              sortDirections: ['descend', 'ascend'],
            });
          }
        });
        this.tableColumns = unique(this.tableColumns);
        this.tableSetLoading = false;
      }, 10);
    },

    /** 媒体渠道筛选
     * @param {*}
     * @return {*}
     */
    async mediaChannelChange(value) {
      this.getMaterialPageListData();
      if (this.activeTemp && this.activeTemp !== '默认指标') await this.getChooseTemp();
    },

    /** 导出Excel
     * @param {*}
     * @return {*}
     */
    async onExportExcelBtnClick() {
      this.excelExporting = true;
      let postData = {
        ...this.tablePagination,
        ...this.filterCondition,
        limit: this.tablePagination.limit - 1,
        pageSize: this.tablePagination.pageSize - 1,
        materialType: this.materialType,
        ...this.tableSorter,
        timeline: this.filterCondition.mediaChannelList === 'GDT' ? this.activeConfigType : null,
      };
      if (postData.materialIds) {
        if (this.filterCondition.materialIds.indexOf(' ') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(' ');
        } else if (this.filterCondition.materialIds.indexOf(',') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(',');
        } else if (this.filterCondition.materialIds.indexOf(';') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(';');
        } else {
          postData.materialIdList = [this.filterCondition.materialIds];
        }
      }
      if (postData.projectCodeList) {
        postData.projectCodeList = [];
        this.filterCondition.projectCodeList.forEach(p => {
          postData.projectCodeList.push(p.value);
        });
      }
      if (this.filterCondition.mediaChannelList) {
        postData.mediaChannelList = Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList];
      } else {
        postData.mediaChannelList = [];
      }

      postData.columns = [];
      this.tableColumns.forEach(item => {
        postData.columns.push({ columnKey: item.dataIndex, columnValue: item.title });
      });
      let res = await exportExcel(postData);
      let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
      let objectUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = objectUrl;
      // link.download = `${this.channelMap[this.activeTabKey]}-${this.dimensionText}`;
      // if (this.filterConditions.startTime) link.download += `-${this.filterConditions.startTime}`;
      // if (this.filterConditions.endTime) link.download += `至${this.filterConditions.endTime}`;
      link.download = `${this.materialType}素材报表.xlsx`;
      link.click();
      URL.revokeObjectURL(objectUrl);
      this.excelExporting = false;
    },

    /** 口径下拉改变
     * @param {*}
     * @return {*}
     */
    onTJKJSelect(item) {
      this.activeConfigType = item;
      this.getMaterialPageListData();
    },

    /** 模板下拉改变
     * @param {String} tempName 模板名称
     * @return {*}
     */
    getTempNameValue(tempName) {
      this.activeTemp = tempName;
      if (this.activeTemp && this.activeTemp !== '默认指标') {
        this.getChooseTemp();
      } else {
        const list = this.menuList.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.setColumn(list);
      }
    },

    /** 点击下拉列表编辑
     * @param {String} tempName 模板名称
     * @return {*}
     */
    async editTemp(tempName) {
      this.operatType = 'edit';
      this.editTempName = tempName;
      if (this.activeTemp && this.activeTemp !== '默认指标') await this.getChooseTemp();
      this.modalVisible = true;
    },

    /** 点击下拉列表删除
     * @param {String} tempName 模板名称
     * @return {*}
     */
    deleteTemp(tempName) {
      this.operatType = 'delete';
      postDeleteTemp([tempName]).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功');
          this.selectedIndicator = [];
          this.getTempName();
          const list = this.menuList.filter(item => {
            return item.key == 'SHARE_DATA';
          })?.[0]?.info;
          this.setColumn(list);
        }
      });
    },

    /** 新增自定义指标
     * @param {*}
     * @return {*}
     */
    addTemp() {
      this.operatType = 'add';
      this.editTempName = null;
      if (this.menuList.length > 0) {
        this.selectedIndicator = this.menuList.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.modalVisible = true;
      } else {
        this.$message.error('当前口径无可设置指标，请联系后台管理员！');
      }
    },

    /** 表格改变
     * @param {Object} tablePagination 表格分页数据
     * @param {Object} tableSorter 表格排序数据
     * @return {*}
     */
    onTableChange(tablePagination, tableSorter) {
      this.tablePagination = tablePagination;
      this.tableSorter = tableSorter;
      this.getMaterialPageListData();
    },

    /** 获取缩略图地址
     * @param {Object} record 行数据
     * @return {*}
     */
    getThumbOSSUrl(record) {
      if (!record.thumbossurl) {
        getUrl(record.thumb).then(url => {
          record.thumbossurl = url;
        });
      } else {
        return record.thumbossurl;
      }
    },

    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },

    /** 遍历切割媒体数据
     * @param {String} Arr 媒体数据
     * @return {Array} 切割后的媒体数据列表
     */
    splitMediaArr(Arr) {
      let mediaArr = [];
      if (Arr != '-' && Arr) {
        mediaArr = Arr.split(',');
        return mediaArr;
      }
    },

    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },

    /** 关闭弹窗
     * @param {Array} tempUserCustoms 已选择指标组合数据
     * @return {*}
     */
    getTempData(tempUserCustoms) {
      if (tempUserCustoms) {
        this.tempUserCustoms = tempUserCustoms;
        let postData = {};
        if (this.operatType === 'add') {
          postData = {
            tempUserCustoms: tempUserCustoms,
          };
          saveNotRestriction(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        } else if (this.operatType === 'edit') {
          postData = {
            oldTempName: this.activeTemp,
            tempUserCustoms: tempUserCustoms,
          };
          postUpdateTemp(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('修改成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        }
      } else {
        this.modalVisible = false;
        this.selectedIndicator = [];
      }
    },

    /** 格式化数组数据
     * @param {Array} arr 需要格式化的数据
     * @return {*}
     */
    formatAmount(arr) {
      arr?.forEach(item => {
        Object.keys(item)?.forEach(key => {
          if (isNumber(item[key] - 0) && key.indexOf('Code') === -1 && key !== 'id') {
            item[key] = addChineseUnit(item[key] - 0);
          }
        });
      });
    },
  },
};
</script>