<!--
 * @Author: Yran
 * @Date: 2021-08-03 10:28:20
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-13 11:24:09
-->
<template>
  <div>
    <div class="table-wrapper">
      <div class="table-toolbar flexJustifyBetween">
        <span class="weight">数据趋势</span>
        <a-space class="toolbar-right" :size="12">
          <SelectMultiple class="select-tagmode" style="width: 144px" v-model:value="tableCondition.subdivisionDimension" :select-data="tableCondition.subdivisionDimension" :label-in-value="true" placeholder="细分维度" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="dimensionOptions" select-option-value="value" select-option-label="label" :disabled="tableLoading" />
          <SelectMultiple class="select-tagmode" style="min-width: 168px" v-model:value="tableCondition.indicatorArray" :select-data="tableCondition.indicatorArray" mode="multiple" :label-in-value="true" placeholder="请选择指标" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="2" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="caliberList" select-option-value="columnValue" select-option-label="columnValue" :disabled="tableLoading" :max-length="2" @change="selectChange" />
        </a-space>
      </div>
      <div class="data-list">
        <div class="data-box flexJustifyBetween mb12" v-for="(data, index) in dataList" :key="index">
          <div v-if="data.url && data.url !== '-'" class="data-info flexAlignBetween" style="height: 100%">
            <div class="flexJustifyBetween">
              <div class="position-rel">
                <img class="table-img" :src="getThumbOSSUrl(data)" :alt="data.fileName" style="cursor: pointer" title="点击预览" />
                <div class="img-hover position-abs flexJustifyCenter flexAlignCenter" style="top: 0" @click="preview(data)">
                  <PlayCircleOutlined class="img-hover-play" :style="{ fontSize: '24px', color: '#fff' }" />
                </div>
                <div v-if="data.reviewStatus === 'Abnormal'" class="position-abs" style="right: 0; top: 6px">
                  <!-- <a-tag color="#E28705" style="border-radius: 4px; font-size: 12px">待投放</a-tag> -->
                  <a-tag color="#AB168D" style="border-radius: 4px; font-size: 12px">审核异常</a-tag>
                </div>
              </div>
              <div class="ml12 flexAlignBetween" style="width: 212px">
                <a-tooltip :title="data.fileName" class="align-l textHide-2rows file-name" style="width: 18em">
                  <a-tag color="#2F70F4" style="border-radius: 4px">{{ columnMap(materialChannelOptions, data.materialChannel, 'value', 'label') }}</a-tag>
                  {{ data.fileName }}
                </a-tooltip>
                <div class="mt10" style="color: #666666">素材ID：{{ data.id }}</div>
              </div>
            </div>
          </div>
          <div class="pl20 data-info" v-else>-</div>
          <div class="data-chart" style="width: 1000px; height: 144px" :ref="`lineChart${index}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 插件 start //
import * as echarts from 'echarts';
import { PlayCircleOutlined } from '@ant-design/icons-vue';
// 插件 end //
// api start //
import { postListSearchCaliber } from '@/api/echarts/index.js';
import { getChartData } from '@/api/materialAnalysis/index.js';
// api end //
// 组件 start //
import { getUrl } from '@/utils/oss.js';
import SelectMultiple from '@/components/selectMultiple/index.vue';
// 组件 end //
export default {
  emits: ['preview'],
  components: { SelectMultiple, PlayCircleOutlined },
  props: {
    filterCondition: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterBtnClick: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    filterCondition: {
      handler(newVal) {},
      deep: true,
    },
    filterBtnClick(newVal) {
      this.getChartData();
    },
  },
  data() {
    this.chartList = {};
    return {
      dataList: [
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-05 21:34:17',
          ctr: '0',
          etag: '"FAD6BFACC2F6A2BA3CA32A4E8A1495EA-11"',
          fileName: '视频素材3.mp4',
          id: 19805,
          makeCode: '',
          materialChannel: 'CG',
          md5: 'e793ae60d65475a2192bdf6064e4639d',
          mediaChannel: '-',
          ofProjectCode: '2021040346005',
          ofProjectName: '同城聊欢',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '凄凄切切',
          resolutionHeight: 1080,
          resolutionLong: 1920,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/videos/2021/08/05/视频素材3-1628170450990.mp4-thumb.png',
          url: '/videos/2021/08/05/视频素材3-1628170450990.mp4',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 112 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 212 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 12 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 23 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 231 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 223 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 122 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 1 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 236 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 172 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 214 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 212 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 213 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 235 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-05 21:34:17',
          ctr: '0',
          etag: '"031C5A85F63BB5E95F8D02D8CEF94DDD-4"',
          fileName: '视频素材1.mp4',
          id: 19804,
          makeCode: '',
          materialChannel: 'CG',
          md5: '43fea671279fc43cd25c5bda3a2d121c',
          mediaChannel: '-',
          ofProjectCode: '2021040346005',
          ofProjectName: '同城聊欢',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '凄凄切切',
          resolutionHeight: 720,
          resolutionLong: 1280,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/videos/2021/08/05/视频素材1-1628170450840.mp4-thumb.png',
          url: '/videos/2021/08/05/视频素材1-1628170450840.mp4',

          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 112 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 2122 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 12 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 23 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 231 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 2123 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 1222 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 21235 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 236 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 1233772 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 2124 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 212 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 21233 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 2315 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-05 21:34:17',
          ctr: '0',
          etag: '"B60605FDBB7872985007D4684F497B76-1"',
          fileName: '视频素材2.mp4',
          id: 19803,
          makeCode: '',
          materialChannel: 'CG',
          md5: '22b4823049d6c1c8e85ce52fa29b9563',
          mediaChannel: '-',
          ofProjectCode: '2021040346005',
          ofProjectName: '同城聊欢',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '凄凄切切123',
          resolutionHeight: 720,
          resolutionLong: 1280,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/videos/2021/08/05/视频素材2-1628170450793.mp4-thumb.png',
          url: '/videos/2021/08/05/视频素材2-1628170450793.mp4',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 134 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 234 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 245 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 342 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 253 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 2345 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 262 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 245 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 26 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 77342 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 245 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 12 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 25 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-03 15:10:31',
          ctr: '0',
          etag: '"9731AFAE7127ABEA422FD9B5A5153878"',
          fileName: 'customer_active.png',
          id: 19790,
          makeCode: '',
          materialChannel: 'YSJJ',
          md5: '9731afae7127abea422fd9b5a5153878',
          mediaChannel: '-',
          ofProjectCode: '20210715020001',
          ofProjectName: '财会盈学堂',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '特殊图111111',
          resolutionHeight: 16,
          resolutionLong: 18,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/images/2021/08/03/customer_active-1627974597628.png-thumb.png',
          url: '/images/2021/08/03/customer_active-1627974597628.png',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 14 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 25 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 452 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 245 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 452 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 462 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 252 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 235 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 264 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 772 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 324 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 12 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 25 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },

        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-03 15:10:31',
          ctr: '0',
          etag: '"9731AFAE7127ABEA422FD9B5A5153878"',
          fileName: 'customer_active.png',
          id: 19790,
          makeCode: '',
          materialChannel: 'YSJJ',
          md5: '9731afae7127abea422fd9b5a5153878',
          mediaChannel: '-',
          ofProjectCode: '20210715020001',
          ofProjectName: '财会盈学堂',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '特殊图111111',
          resolutionHeight: 16,
          resolutionLong: 18,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/images/2021/08/03/customer_active-1627974597628.png-thumb.png',
          url: '/images/2021/08/03/customer_active-1627974597628.png',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 112 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 2122 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 12 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 23 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 231 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 2123 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 1222 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 21235 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 236 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 1233772 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 2124 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 212 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 21233 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 2315 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-03 15:10:31',
          ctr: '0',
          etag: '"9731AFAE7127ABEA422FD9B5A5153878"',
          fileName: 'customer_active.png',
          id: 19790,
          makeCode: '',
          materialChannel: 'YSJJ',
          md5: '9731afae7127abea422fd9b5a5153878',
          mediaChannel: '-',
          ofProjectCode: '20210715020001',
          ofProjectName: '财会盈学堂',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '特殊图111111',
          resolutionHeight: 16,
          resolutionLong: 18,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/images/2021/08/03/customer_active-1627974597628.png-thumb.png',
          url: '/images/2021/08/03/customer_active-1627974597628.png',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 1 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 23 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 42 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 26 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 542 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 52 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 2232 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 25 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 236 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 32772 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 424 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 242 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 12 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 25 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-03 15:10:31',
          ctr: '0',
          etag: '"9731AFAE7127ABEA422FD9B5A5153878"',
          fileName: 'customer_active.png',
          id: 19790,
          makeCode: '',
          materialChannel: 'YSJJ',
          md5: '9731afae7127abea422fd9b5a5153878',
          mediaChannel: '-',
          ofProjectCode: '20210715020001',
          ofProjectName: '财会盈学堂',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '特殊图111111',
          resolutionHeight: 16,
          resolutionLong: 18,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/images/2021/08/03/customer_active-1627974597628.png-thumb.png',
          url: '/images/2021/08/03/customer_active-1627974597628.png',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 134 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 234 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 245 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 342 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 253 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 2345 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 262 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 245 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 26 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 77342 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 245 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 12 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 25 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
        {
          activated_cost: '0',
          activated_count: 0,
          activated_rate: '0',
          click: 0,
          cost: 0,
          cpc: '0',
          createTime: '2021-08-03 15:10:31',
          ctr: '0',
          etag: '"9731AFAE7127ABEA422FD9B5A5153878"',
          fileName: 'customer_active.png',
          id: 19790,
          makeCode: '',
          materialChannel: 'YSJJ',
          md5: '9731afae7127abea422fd9b5a5153878',
          mediaChannel: '-',
          ofProjectCode: '20210715020001',
          ofProjectName: '财会盈学堂',
          play_3s_ratio: 0,
          play_end_ratio: 0,
          postName: 'admin',
          remarkInfo: '特殊图111111',
          resolutionHeight: 16,
          resolutionLong: 18,
          retention_cost: '0',
          retention_count: 0,
          retention_rate: '0',
          show: 0,
          thousand_display_price: '0',
          thumb: '/images/2021/08/03/customer_active-1627974597628.png-thumb.png',
          url: '/images/2021/08/03/customer_active-1627974597628.png',
          userId: 1,
          lineData: [
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-12', data: 14 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-13', data: 25 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-14', data: 452 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-15', data: 245 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-16', data: 452 },
            { currentName: '成本', dataType: 0, type: 0, date: '2021-01-17', data: 462 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-12', data: 252 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-13', data: 235 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-14', data: 264 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-15', data: 772 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-16', data: 324 },
            { currentName: '花费', dataType: 0, type: 1, date: '2021-01-17', data: 24 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-12', data: 1 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-13', data: 22 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-14', data: 12 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-15', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-16', data: 23 },
            { currentName: '腾讯广告-成本', dataType: 0, type: 2, date: '2021-01-17', data: 23 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-12', data: 22 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-13', data: 25 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-14', data: 26 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-15', data: 772 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-16', data: 24 },
            { currentName: '腾讯广告-花费', dataType: 0, type: 3, date: '2021-01-17', data: 24 },
          ],
        },
      ],
      // 折线图基本配置
      lineOption: {
        dataZoom: {
          type: 'inside',
          xAxisIndex: 0,
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '30px',
          top: '0',
          containLabel: false,
          // y2:160
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)',
              },
            },
            axisTick: {
              alignWithLabel: true,
              interval: 'auto',
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.25)',
              },
            },
            axisLabel: { color: 'rgba(0, 0, 0, 0.45)' },
          },
        ],
        yAxis: [
          {
            type: 'value',
            show: false,
          },
          {
            type: 'value',
            show: false,
          },
        ],
        series: [],
      },
      tableCondition: {}, // 表格工具栏筛选项
      tableLoading: false, // 表格加载
      tableSetLoading: false, // 表格列加载
      dimensionOptions: [
        { label: '按媒体', value: 'Channel' },
        { label: '按版位', value: 'solt' },
      ], // 维度列表
      excelExporting: false, // 导出excel加载
      tempList: [], // 模板列表
      materialTableData: [], // 表格数据
      tableColumns: [], // 表格列
      tablePagination: {
        // 表格分页
        limit: '31',
        page: '1',
        pageSize: 51,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['11', '21', '31', '51'],
      },
      materialChannelOptions: [
        // 素材类型
        { label: '内部制作', value: 'NBZZ' },
        { label: '外部采购', value: 'WBCG' },
        { label: '客户提供', value: 'KHTG' },
        { label: '情景剧', value: 'QJJ' },
        { label: '剪辑', value: 'YSJJ' },
        // { label: 'AE', value: 'AE' },
        { label: '采购', value: 'CG' },
      ],
      selectedIndicator: [], // 已选指标项
      fixedList: [], // 固定指标项
      editTempName: null, // 编辑列模板
      menuList: [], // 模板列表
      modalVisible: false, // 是否打开自定义指标弹窗
      mediaList: [
        // 媒体渠道
        { text: '巨量引擎', value: 'TT' },
        { text: '腾讯广告', value: 'GDT' },
        { text: '磁力引擎', value: 'KS' },
      ],
      materialType: this.$route.query.materialType || 'IMAGE', // 当前页面是图片数据还是视频数据
      caliberList: [], // 指标数组
    };
  },
  mounted() {
    this.createLineBar();
    this.getListSearchCaliber();
  },
  methods: {
    /** 获取表格数据
     * @param {*}
     * @return {*}
     */
    async getChartData() {
      const postData = {
        ...this.filterCondition,
        ...this.tableCondition,
      };
      let res = await getChartData(postData);
    },
    /** 获取指标数据合计
     * @param {*}
     * @return {*}
     */
    getListSearchCaliber() {
      let postData = {
        mediaChannel: null,
      };
      postListSearchCaliber(postData).then(res => {
        this.caliberList = res.list;
      });
    },
    /** 限制多选框个数
     * @param {*} value
     * @param {*} option
     * @return {*}
     */
    selectChange(value, option) {
      const nmb = this.tableCondition.subdivisionDimension ? 1 : 2;
      if (value.length > nmb) {
        this.tableCondition.indicatorArray.splice(2, value.length - nmb);
        this.$message.error('已超过最大选择数量！');
      }
    },

    /** 创建折线图
     * @param {*}
     * @return {*}
     */
    createLineBar() {
      this.dataList.forEach((data, index) => {
        if (this.chartList['lineChart' + index] != null && this.chartList['lineChart' + index] != '' && this.chartList['lineChart' + index] != undefined) {
          this.chartList['lineChart' + index].dispose();
        }
        this.chartList['lineChart' + index] = echarts.init(this.$refs['lineChart' + index]);
        let datas = data.lineData;
        let lineOption = JSON.parse(JSON.stringify(this.lineOption));
        datas.forEach(item => {
          let name = item.label || item.currentName;
          if (lineOption.series.length > 0) {
            let flag = false;
            lineOption.series.forEach(series => {
              if (series && series.name === name) {
                series.data.push(item.data);
                lineOption.xAxis[0].data.push(item.date);
                flag = true;
              }
            });
            if (!lineOption.yAxis[item.type]) {
              lineOption.yAxis.push({
                type: 'value',
                show: false,
              });
            }
            if (!flag) {
              lineOption.series.push({
                name: name,
                type: 'line',
                smooth: true,
                itemStyle: { opacity: 0 },
                xAxisIndex: 0,
                yAxisIndex: item.type,
                data: [item.data],
                emphasis: {
                  focus: 'series',
                },
              });
              lineOption.xAxis[0].data.push(item.date);
            }
          } else {
            lineOption.series.push({
              name: name,
              type: 'line',
              smooth: true,
              itemStyle: { opacity: 0 },
              xAxisIndex: 0,
              yAxisIndex: item.type,
              data: [item.data],
              emphasis: {
                focus: 'series',
              },
            });
            lineOption.xAxis[0].data.push(item.date);
          }
        });
        lineOption.xAxis[0].data = Array.from(new Set(lineOption.xAxis[0].data));
        lineOption.series.forEach((item, index) => {
          item.itemStyle = item.itemStyle ? item.itemStyle : {};
          // item.itemStyle.color = index % 2 === 0 ? '#06C86F' : '#1A7BFB';
          item.lineStyle = item.lineStyle ? item.lineStyle : {};
        });
        this.chartList['lineChart' + index].setOption(lineOption);
      });
    },

    /** 预览视频
     * @param {*} fileName 视频名称
     * @return {*}
     */
    async preview(record) {
      this.$emit('preview', record);
    },

    /** 获取缩略图地址
     * @param {Object} record 行数据
     * @return {*}
     */
    getThumbOSSUrl(record) {
      if (!record.thumbossurl) {
        getUrl(record.thumb).then(url => {
          record.thumbossurl = url;
        });
      } else {
        return record.thumbossurl;
      }
    },

    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },

    /** 遍历切割媒体数据
     * @param {String} Arr 媒体数据
     * @return {Array} 切割后的媒体数据列表
     */
    splitMediaArr(Arr) {
      let mediaArr = [];
      if (Arr != '-' && Arr) {
        mediaArr = Arr.split(',');
        return mediaArr;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.data-box {
  width: 44.25rem;
  height: 144px;
  background: #ffffff;
  border: 1px solid #eff3f7;
  border-radius: 8px;
  .data-info {
    width: 13.0625rem;
    margin-right: 1.3125rem;
    background: #f9fcfe;
    padding: 26px 18px;
    .table-img {
      width: 160px;
      height: 90px;
    }
    .img-hover {
      width: 160px;
      height: 90px;
    }
    .file-name {
      color: #010101;
      word-break: break-all;
    }
  }
}
</style>