<!--
 * @Author: Yran
 * @Date: 2021-08-02 18:36:32
 * @LastEditors: Yran
 * @LastEditTime: 2021-08-16 16:47:10
-->
<template>
  <div class="material-analysis">
    <div class="filter-container" @click.self="closeModal">
      <a-tabs v-model:activeKey="activeKey" style="width: 100%">
        <a-tab-pane key="trend" tab="趋势图"></a-tab-pane>
        <a-tab-pane key="dataTable" tab="数据表格"></a-tab-pane>
      </a-tabs>
      <div class="flexJustifyBetween">
        <a-form class="filter-content flexJustifyStart flexAlignCenter" ref="filterForm" :rules="filterRules" :model="filterCondition">
          <span>筛选：</span>
          <a-row class="ml18">
            <a-col>
              <a-form-item class="mb0">
                <a-input style="height: 32px; width: 216px" v-model:value="filterCondition.materialIDList" placeholder="批量查询素材ID请用空格隔开" @press-enter="enterIdSearch"></a-input>
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item class="mb0">
                <SelectMultiple class="select-tagmode" style="width: 144px" v-model:value="filterCondition.projectCodeList" :select-data="filterCondition.projectCodeList" mode="multiple" :label-in-value="true" placeholder="请选择产品" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="projectOptions" select-option-value="projectCode" select-option-label="projectName" :disabled="tableLoading" />
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item class="mb0">
                <SelectMultiple class="select-tagmode" style="width: 156px" v-model:value="filterCondition.promotionTargeType" :select-data="filterCondition.promotionTargeType" :label-in-value="true" placeholder="请选择推广目标类型" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="promotionTargeTypeOptions" select-option-value="projectCode" select-option-label="projectName" :disabled="tableLoading" />
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item class="mb0">
                <a-select style="width: 144px" placeholder="请选择媒体渠道" v-model:value="filterCondition.mediaChannelList" allow-clear :max-tag-count="1" :show-arrow="true" :disabled="tableLoading">
                  <!-- <a-select-option value="JLQC">巨量千川</a-select-option> -->
                  <a-select-option value="TT">巨量引擎</a-select-option>
                  <a-select-option value="GDT">腾讯广告</a-select-option>
                  <!-- <a-select-option value="WXGG">微信广告</a-select-option> -->
                  <a-select-option value="KS">磁力引擎</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item class="mb0">
                <SelectMultiple class="select-tagmode" style="width: 144px" v-model:value="filterCondition.materialSlot" :select-data="filterCondition.materialSlot" :label-in-value="true" placeholder="请选择版位" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="materialSlotOptions" select-option-value="projectCode" select-option-label="projectName" :disabled="tableLoading" />
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item class="mb0">
                <a-range-picker style="width: 240px" :placeholder="['上传开始日期', '上传结束日期']" separator="到" v-model:value="filterCondition.uploadTimeValue" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDate" @change="onDateUploadRangeChange" :disabled="tableLoading">
                  <template #suffixIcon>
                    <CalendarTwoTone />
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="filter-buttons flexJustifyBetween">
          <a-button class="btn filter-btn" type="primary" @click="onFilterBtnClick" :disabled="tableLoading">筛选</a-button>
          <a-button class="btn cancen-btn" @click="onResetBtnClick" :disabled="tableLoading">重置</a-button>
        </div>
      </div>
    </div>
    <Trend v-if="activeKey === 'trend'" :filter-condition="filterCondition" :filter-btn-click="filterBtnClick" @preview="preview" />
    <DataTable v-if="activeKey === 'dataTable'" :filter-condition="filterCondition" :filter-btn-click="filterBtnClick" @preview="preview" />
    <!-- 视频素材预览弹框 -->
    <a-modal :centered="true" class="video-modal" v-model:visible="viewActorVideosVisible" :mask-closable="false" :footer="null" :destroy-on-close="true" @cancel="closeModal">
      <a-carousel arrows :draggable="true" :autoplay="true" class="video-carousel">
        <video-player v-if="playerOptions.src" v-bind="playerOptions"></video-player>
      </a-carousel>
      <div class="preview-action">
        <a-space>
          <!-- <a-button @click="viewDetailFromPreview(previewRecord)">详情</a-button> -->
          <a-button :loading="downloading" @click="download(playerOptions.src, playerOptions.title)">下载</a-button>
        </a-space>
      </div>
      <template #closeIcon> <CloseOutlined :style="{ color: '#fff', fontSize: '24px' }" /> </template>
    </a-modal>
    <!-- 图片素材预览弹框 -->
    <a-modal :centered="true" class="picture-modal" v-model:visible="viewActorImagesVisible" :footer="null">
      <a-carousel arrows :draggable="true" :autoplay="true" class="picture-carousel">
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <LeftCircleOutlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <RightCircleOutlined />
          </div>
        </template>
        <img v-for="item in actorPictureDataList" :key="item" :src="item" class="view-image" style="object-fit: contain" />
      </a-carousel>

      <div class="preview-action flexJustifyBetween">
        <a-space>
          <a :href="previewImgInfo.ossurl" :download="previewImgInfo.fileName" target="_blank" @click="getOSSUrl(previewImgInfo)">下载图片</a>
          <!-- <a @click="onViewDetailsClick(previewImgInfo)">详情</a> -->
        </a-space>
        <span>{{ previewImgInfo.resolutionLong && previewImgInfo.resolutionHeight ? `${previewImgInfo.resolutionLong} * ${previewImgInfo.resolutionHeight}` : '--' }}</span>
      </div>
      <template #closeIcon> <CloseOutlined :style="{ color: '#fff', fontSize: '24px' }" /> </template>
    </a-modal>
  </div>
</template>
<script>
// 插件 start //
import moment from 'moment';
import { CalendarTwoTone, CloseOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
// 插件 end //

// api start //
import { getProjectListByCompanyName } from '@/api/base/project.js';
import { getPromotionTargeType, getSlot } from '@/api/materialAnalysis/index.js';
// api end //

// 组件 start //
import { getUrl } from '@/utils/oss.js';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import DataTable from './dataTable.vue';
import Trend from './trend.vue';
import videoPlayer from '@/components/Video/videoPlayer';
// 组件 end //
export default {
  components: { SelectMultiple, CalendarTwoTone, DataTable, Trend, CloseOutlined, LeftCircleOutlined, RightCircleOutlined, videoPlayer },
  data() {
    return {
      // 选中tab
      activeKey: 'trend',
      // 筛选值
      filterCondition: {},
      // 产品列表
      projectOptions: [],
      // 推广目标类型列表
      promotionTargeTypeOptions: [],
      // 素材版位列表
      materialSlotOptions: [],
      // 表格加载
      tableLoading: false,
      // 表单校验
      filterRules: { playTimeValue: [{ required: true, message: '请选择投放时间', trigger: 'blur', type: 'array' }] },
      // 日期格式化
      dateRangeFormat: 'YYYY-MM-DD',
      // 预设日期
      dateRanges: {
        昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        最近三天: [moment().subtract(3, 'days'), moment().subtract(1, 'days')],
        本周: [moment().startOf('week'), moment.min(moment().subtract(1, 'days'), moment().endOf('week'))],
        最近七天: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
        本月: [moment().startOf('month'), moment.min(moment().subtract(1, 'days'), moment().endOf('month'))],
        最近三十天: [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
        最近九十天: [moment().subtract(90, 'days'), moment().subtract(1, 'days')],
      },
      // 点击筛选
      filterBtnClick: [],
      materialType: this.$route.query.materialType || 'IMAGE', // 当前页面是图片数据还是视频数据
      viewActorVideosVisible: false, // 视频素材预览
      viewActorImagesVisible: false, // 图片素材预览
      playerOptions: { autoplay: true, width: 1000, height: 600 }, // 视频预览配置
      downloading: false, // 素材下载加载
      actorPictureDataList: [], // 预览图片列表
      previewImgInfo: {}, // 查看的图片行信息
    };
  },
  mounted() {
    this.getProjectListFilter();
  },
  methods: {
    /** 获取推广目标类型列表
     * @param {*}
     * @return {*}
     */
    getPromotionTargeType() {
      getPromotionTargeType().then(res => {
        this.promotionTargeTypeOptions = res.list;
      });
    },
    /** 获取素材版位信息列表
     * @param {*}
     * @return {*}
     */
    getSlot() {
      getSlot().then(res => {
        this.materialSlotOptions = res.list;
      });
    },
    // 筛选的产品列表
    async getProjectListFilter() {
      let res = await getProjectListByCompanyName({});
      if (res.code == 0) {
        this.projectOptions = res.page?.list;
      }
    },
    /** 预览视频
     * @param {*} fileName 视频名称
     * @return {*}
     */
    async preview(record) {
      if (!record.ossurl) {
        await this.getOSSUrl(record);
      }
      if (this.materialType === 'IMAGE') {
        let fileName = record.ossurl;
        this.previewImgInfo = record;
        this.actorPictureDataList = [];
        this.actorPictureDataList.push(fileName);
        let image = new Image();
        image.src = fileName;
        this.viewActorImagesVisible = true;
      } else {
        this.viewActorVideosVisible = true;
        this.playerOptions = {};
        this.$nextTick(() => {
          this.previewRecord = record;
          this.playerOptions = { title: record.fileName, src: record.ossurl, autoplay: true, width: 1000, height: 600 };
        });
      }
    },

    /** 获取素材地址
     * @param {Object} record 行数据
     * @return {*}
     */
    async getOSSUrl(record) {
      if (!record.ossurl) {
        record.ossurl = await getUrl(record.url);
      }
      return record.ossurl;
    },

    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    closeModal() {
      const drawer = document.querySelector('.viewMaterialDetails');
      if (drawer) {
        drawer.removeEventListener('click', e => {
          e.stopPropagation();
        });
      }
      this.viewActorVideosVisible = false;
    },

    /** 素材下载
     * @param {String} src 素材路径
     * @param {String} filename 素材名称
     * @param {Function} callback 回调函数
     * @return {*}
     */
    download(src, filename, callback) {
      this.downloading = true;
      let _this = this;
      downloadFile(src, filename, undefined, () => {
        callback && callback();
        _this.downloading = false;
      });
    },
    enterIdSearch() {},
    onFilterBtnClick() {
      this.filterBtnClick = new Array();
    },
    onResetBtnClick() {
      this.filterCondition = {};
      this.filterBtnClick = new Array();
    },
    onDateUploadRangeChange(time) {
      this.filterCondition.uploadStartTime = time[0].format('YYYY-MM-DD');
      this.filterCondition.uploadEndTime = time[1].format('YYYY-MM-DD');
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
  },
};
</script>
<style lang="less" scoped>
.material-analysis {
  width: 45rem;
  margin: 0 auto;
}
</style>
<style lang="less">
.material-analysis {
  .ant-tabs-nav .ant-tabs-tab {
    padding-top: 3px;
    font-size: 14px;
  }
}
.picture-modal {
  min-height: 100px;
  padding: 0;
  background: #fff;
  .picture-carousel {
    width: 100%;
  }
  .ant-modal-body {
    padding: 0;
  }

  .preview-action {
    height: 3em;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 2em;
    padding-left: 2em;
  }
  .anticon.anticon-close {
    background: #8a8383;
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &:hover {
      background: #b9b0b0;
      color: #191616 !important;
    }
  }
}
.video-modal {
  width: 1000px !important;
  padding: 0;
  background: #fff;
  .ant-modal-body {
    padding: 0;
  }
  .anticon.anticon-close {
    background: #8a8383;
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &:hover {
      background: #b9b0b0;
      color: #191616 !important;
    }
  }
  .preview-action {
    height: 3em;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 2em;
  }
}
</style>