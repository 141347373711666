<!--
 * @Author: chenxing
 * @Date: 2021-06-03 11:33:27
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-09 15:23:05
-->
<template>
  <a-table v-fixed-scroll size="small" ref="customizeTable" bordered :columns="colsSetting" :data-source="tableData" :row-selection="rowSelection" :row-key="rowKey" :pagination="tablePagination" :loading="tableLoading" :scroll="{ x: 'max-content' }" class="" :class="{ 'ant-table-fixed-first-row': fixedFirstRow, 'ant-table-striped': stripShow }" :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : '')" :custom-row="(record, index) => rowClick(record, index)" :custom-header-row="(record, index) => rowHeaderClick(record, index)" @change="onTableChange">
    <template v-for="s in slots" #[s.slots.customRender]="{ text, record, index }">
      <slot :name="s.slots.customRender" :text="text" :record="record" :index="index" class="column48" > </slot>
    </template>
  </a-table>
  <!-- <a></a> -->
</template>

<script>
import { getDocumentScroll } from '@/utils/utils.js';
export default {
  emits: ['tableChange', 'rowClick', 'rowHeaderClick'],
  props: {
    colsSetting: {
      type: Array,
      default() {
        return [];
      },
    },
    colsTemplate: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    rowSelection: {
      type: [Object, String],
      default: null,
    },
    fixedFirstRow: {
      type: Boolean,
      default: false,
    },
    stripShow: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => {
        return { limit: '10', page: '1', pageSize: 10, showTotal: total => `总计${total}条数据`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] };
      },
    },
    rowKey: {
      type: [String, Function],
      default: 'account_id',
    },
  },
  data() {
    return {
      // tableHeight: 1920,
      tablePagination: {},
      tableSorter: { orderType: 'cost', order: 'desc' },
      slots: this.colsSetting.filter(setting => {
        return setting.slots && setting.slots.customRender;
      }),
    };
  },
  watch: {
    colsSetting(newVal) {
      this.slots = newVal.filter(setting => {
        return setting.slots && setting.slots.customRender;
      });
    },
    pagination(newVal) {
      this.tablePagination = newVal;
    },
    // tableHeight(newVal) {
    //   this.tableHeight = newVal;
    // },
  },
  mounted() {
    this.tablePagination = this.pagination;
    // window.addEventListener('resize', () => (this.tableHeight = getDocumentScroll()), false);
    // this.tableHeight = getDocumentScroll();
  },
  methods: {
    rowClick(record, index) {
      return {
        onClick: event => {
          this.$emit('rowClick', record, index, event);
        },
      };
    },
    rowHeaderClick(record, index) {
      return {
        onClick: event => {
          this.$emit('rowHeaderClick', record, index, event);
        },
      };
    },
    onTableChange(pagination, filters, sorter) {
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;
      this.tableSorter.orderType = sorter.field;
      this.tableSorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';
      this.$emit('tableChange', this.tablePagination, this.tableSorter);
    },
  },
};
</script>

<style lang="less" scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
.ant-table-fixed-first-row :deep(.ant-table-scroll) tbody tr:nth-child(1) td {
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.ant-table-fixed-first-row {
  :deep(.ant-table-fixed-left) {
    z-index: 11;
  }
}
</style>

<style>
.ps__rail-x {
  display: block;
  z-index: 12; /*大于fixed table 的z-index*/
}

.ant-table-body {
  position: relative;
}
</style>