/*
 * @Author: Yran
 * @Date: 2021-08-09 17:16:26
 * @LastEditors: Yran
 * @LastEditTime: 2021-08-09 17:22:45
 */
import axios from '@/utils/axios';

const apiUrl = {
    getChartData: '',
    getDataList: '',
    getPromotionTargeType: '',
    getSlot: '',
};

// 获取数据趋势图表数据
export function getChartData(params) {
    return axios.post(apiUrl.getChartData, params);
}
// 获取数据表格数据
export function getDataList(params) {
    return axios.post(apiUrl.getDataList, params);
}

// 获取推广目标
export function getPromotionTargeType(params) {
    return axios.post(apiUrl.getPromotionTargeType, params);
}
// 获取素材版位
export function getSlot(params) {
    return axios.post(apiUrl.getSlot, params);
}